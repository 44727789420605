import React from 'react';
import * as Self from './module';
import { registerModule } from '../../app/modules/reactModules';
import ModuleStyle from './module.style';
import Heading from '../../components/global/Heading';
import RichText from '../../components/global/RichText';
import Button from '../../components/global/Button';
import Link from '../../components/global/Link';
import Icon from '../../components/global/Icon';
import Typography from '../../components/global/Typography';
import Picture from '../../components/global/Picture';
import { ArrowRight } from '../../app/helpers/icons';
import { useBreakpoint } from '../../app/providers/breakpointProvider';

export const getServerSideProps = async () => {
  return {
    props: {},
    maxage: 60,
    staleWhileRevalidate: 300,
  };
};

const MissionStatementModule = ({
  companyFace,
  title,
  text,
  button,
  link,
  wlModule,
  wlLayout,
  ...p
}) => {
  const smallScreenSize = useBreakpoint().isDown('desktopSmall');

  const companyFaceContent = (
    <div className='mission-stat-face-content'>
      <Picture
        images={{
          1: companyFace.image.src,
        }}
        alt={companyFace.image.alt}
        loading={companyFace.image.loading}
        {...(smallScreenSize ?
          {
            width: 120,
            height: 120
          } : {
            width: 190,
            height: 190
          }
        )}
        className="picture"
      />
      <div>
        <Typography
          color="blueCharcoal"
          variant="heading5"
          className="name"
        >
          {companyFace.name.content}
        </Typography>
        <Typography
          color="blueCharcoal"
          variant="intro"
          className="role"
        >
          {companyFace.role.content}
        </Typography>
      </div>
    </div>
  )

  const textContent = (
    <div className='mission-stat-text-content'>
      {!!title && <Heading title={title} className="title" />}
      {!!text?.content && <RichText content={text.content} className="text" />}
      {!!button?.content && (
        <Button
          className="button"
          href={button.href}
          nofollow={button.nofollow}
          rel={button.rel}
          target={button.target}
          variant={button.variant}
          large
        >
          {button.content}
        </Button>
      )}
      {!!link?.content && (
        <Link
          className="link"
          href={link.href}
          nofollow={link.nofollow}
          rel={link.rel}
          target={link.target}
        >
          {link.content}
          <Icon {...ArrowRight} />
        </Link>
      )}
    </div>
  );

  return (
    <ModuleStyle
      xPaddingAsMargin
      flex
      col
      gap="20px"
      wlModule={wlModule}
      wlLayout="centeredbox"
      className="plain"
      {...p}
    >
      <div className='mission-stat-content'>
        { companyFaceContent }
        { textContent }
      </div>
    </ModuleStyle>
  );
};

export default MissionStatementModule;

registerModule({ MissionStatement: Self });
