import styled from '@emotion/styled';
import Container from '../../components/global/Container';

export default styled(Container)`
  & > .layout-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mission-stat-content {
    display: flex;
    flex-direction: column-reverse;
    padding: 15px;
    background-color: ${({ theme }) => theme.colors.aliceBlue};

    ${({ theme }) => theme.media.desktopSmall} {
      flex-direction: row;
      padding: 60px;
    }
  }

  .mission-stat-face-content {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-basis: 100%;
    row-gap: 5px;
    ${({ theme }) => theme.media.desktopSmall} {
      text-align: center;
      flex-direction: column;
      flex-basis: 40%;
    }

    .picture {
      border-radius: 50%;
      margin-right: 15px;

      ${({ theme }) => theme.media.desktopSmall} {
        margin: 0;
      }
    }
  }

  .mission-stat-text-content {
    flex-basis: 100%;
    ${({ theme }) => theme.media.desktopSmall} {
      flex-basis: 60%;
    }

    .title {
      margin-bottom: 15px;
      ${({ theme }) => theme.media.desktopSmall} {
        margin-bottom: 20px;
      }
    }

    .text {
      margin-bottom: 30px;
    }

    .button {
      margin-right: 30px;
    }

    .link {
      display: inline-flex;
      align-items: center;
      width: fit-content;
      color: ${({ theme }) => theme.colors.pacificBlue};

      svg {
        margin-left: 12px;
      }

      margin-top: 20px;
      ${({ theme }) => theme.media.desktopSmall} {
        margin: 0;
      }
    }
  }
`;
